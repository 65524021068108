import React, { useEffect, useState } from "react";
import "../CSS/customProducts.css";
import Loader from "../../../components/Loaders/Loader";
import { CartFill, Heart, HeartFill } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import ProductDetailSlider from "../../utility/Slider/ProductDetailSlider";
import GetOneProductHook from "../../../hooks/product/getOneProductHook";
import ProductSlider from "../../utility/Slider/Product-Slider";
import Navbar from "../../utility/navbar/navbar";
import Footer from "../../utility/Footer/Footer";
import { useAddCartItemMutation } from "../../../RTK/Api/CartApi";
import { useAddWishlistItemMutation } from "../../../RTK/Api/WishlistApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import TransHook from "../../../hooks/translation/trans-hook";
import StarRatings from "react-star-ratings";
import useSanitizedHook from "../../../hooks/sanitizeHtml/useSanitizedHook";
import calculateAndFormatPrice from "../../../hooks/GobalHooks.js/FormatPrice";

const ProductDetails = () => {
  const { id } = useParams();
  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  const {
    data,
    isLoading,
    activeTab,
    slides,
    top10Sold,
    maxAmount,
    inputValue,
    alternateProducts,
    setActiveTab,
    handleTabClick,
    handleInputChange,
  } = GetOneProductHook(id);

  const navigate = useNavigate();
  const { sanitizedShortDescription, sanitizedLongDescription } =
    useSanitizedHook(lang, data);
  const currObj = JSON.parse(Cookies.get("CompanyCurrency"));

  const [addCartItem, { error }] = useAddCartItemMutation();
  if (error) toast.error(error);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
  }, []);
  const [Pname, setPname] = useState("");
  const [price, setPrice] = useState("");
  useEffect(() => {
    if (data) {
      lang === "ar"
        ? setPname(data?.nameAR)
        : lang === "tr"
        ? setPname(data?.nameTR)
        : setPname(data?.name);
    }
  }, [data, lang]);
  useEffect(() => {
    if (data?.currency) {
      const price = calculateAndFormatPrice(
        data?.ecommercePrice,
        data?.ecommercePriceAftereDiscount,
        Array.isArray(data?.currency)
          ? data.currency[0]?.exchangeRate
          : data?.currency?.exchangeRate
      );

      setPrice(price);
    }
  }, [data]);
  const addToCart = (product) => {
    if (userData) {
      addCartItem({
        qr: product?.qr,
        taxPrice: price,
        quantity: inputValue || 1,
      })
        .unwrap()
        .then(() => toast.success(t("addedToCart")));
    } else {
      toast.warn(t("pleaseLogin"));
    }
  };

  // Add to Wishlist functionality
  const [addWishlistItem, { error: addError }] = useAddWishlistItemMutation();
  if (addError) toast.error(addError);

  const handleAddItem = async (itemId) => {
    try {
      if (userData) {
        if (!isWishlisted) {
          await addWishlistItem({ productId: itemId })
            .unwrap()
            .then(() => {
              const userData = JSON.parse(Cookies.get("userData"));
              userData.wishlist.push(itemId);
              Cookies.set("userData", JSON.stringify(userData));
              toast.success(t("addedToWishlist"));
            });
        } else {
          toast.info(t("alreadyWishlisted"));
        }
      } else {
        toast.warn(t("pleaseLogin"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const openReviews = () => {
    setActiveTab("product-reviews-content");
  };

  const [isWishlisted, setIsWishlisted] = useState(false);

  useEffect(() => {
    if (userData) {
      const userWishlist = userData.wishlist;
      if (userWishlist.includes(id)) {
        setIsWishlisted(true);
      }
    }
  }, [userData, id]);

  if (isLoading) {
    return (
      <>
        <Navbar />
        <Loader />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div
          className={`container ${lang === "ar" ? "arabic-text" : ""}`}
          style={{ marginTop: screen === "large" ? "240px" : "110px" }}
        >
          {/* Hero START */}
          <div className="product-single-container product-single-default">
            <div className="row justify-content-around">
              {/* Main slider START */}
              <div className="col-lg-5 col-md-6 product-single-gallery">
                <ProductDetailSlider slides={4} Array={data} />
              </div>

              {/* Content START */}
              <div className="col-lg-6 col-md-6 product-single-details">
                <h1 className="product-title mt-1">
                  {Pname.length > 200 ? Pname.substring(0, 200) + "..." : Pname}
                </h1>

                {/* Ratings START */}
                <div className="ratings-container">
                  <StarRatings
                    rating={data?.ratingsAverage}
                    starRatedColor="#fe9900"
                    starEmptyColor="lightgray"
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing=".5px"
                  />

                  <span className="rating-link" onClick={() => openReviews()}>
                    ({data?.ratingsQuantity} {t("ratings")})
                  </span>
                </div>

                <hr className="short-divider" />

                {/* Price START */}
                <div className="price-box">
                  <span className="product-price">
                    {currObj.currencyCode}
                    {price}
                  </span>
                </div>
                {/* Price END */}

                {/* Description START */}
                <div className="product-desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizedShortDescription,
                    }}
                  />
                </div>
                {/* Description END */}

                {/* Add to cart START */}
                <div className="product-action">
                  <div className="price-box product-filtered-price">
                    {data?.ecommercePriceAftereDiscount ? (
                      <del className="old-price">
                        <span>{price}</span>
                      </del>
                    ) : null}
                    <span className="product-price">{price}</span>
                  </div>

                  {maxAmount > 0 ? (
                    <>
                      <div className="product-single-qty">
                        <input
                          className="horizontal-quantity form-control"
                          type="number"
                          max={maxAmount}
                          min={0}
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                      </div>

                      <button
                        onClick={() => addToCart(data)}
                        className="btn btn-dark mr-2 addToCartBtn"
                        title={t("addToCart")}
                      >
                        <CartFill />
                        &nbsp;
                        <span>{t("addToCart")}</span>
                      </button>
                      <button
                        onClick={() => handleAddItem(data?._id)}
                        className="btn btn-icon-wish add-wishlist"
                        title={t("addToWishlist")}
                      >
                        {isWishlisted ? (
                          <>
                            <HeartFill />
                          </>
                        ) : (
                          <>
                            <Heart />
                          </>
                        )}
                      </button>
                    </>
                  ) : (
                    <div className="alert alert-danger">
                      {t("itemNotAvailable")}
                    </div>
                  )}
                </div>
                {/* Add to cart END */}

                <hr className="divider mb-0 mt-0" />

                {/* Specifcations Start */}
                {data?.customAttributes && (
                  <div className="product_Spec">
                    <ul className="d-flex">
                      {data?.customAttributes?.map((attr) => {
                        return (
                          <li
                            className={screen === "large" ? "col-2" : "col-3"}
                          >
                            <h6>{attr?.key?.substring(0, 10)}</h6>
                            {attr?.value?.length > 10 ? (
                              <span> {attr?.value?.substring(0, 10)} ...</span>
                            ) : (
                              <span> {attr?.value}</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {/* Specifcations End */}

                {/* Share to social media and wishlist START */}
                {/* <div className="product-single-share mb-3">
                  <label className="sr-only">{t("share")}:</label>

                  <div className="social-icons mx-2">
                    <Link
                      to={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
                      className="social-icon mx-1 social-facebook"
                      target="_blank"
                      title="Facebook"
                    >
                      <Facebook />
                    </Link>
                    <Link
                      to={`https://x.com/intent/tweet?url=${pageUrl}`}
                      className="social-icon mx-1 social-twitter"
                      target="_blank"
                      title="X"
                    >
                      <TwitterX />
                    </Link>
                    <Link
                      to={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                      className="social-icon mx-1 social-linkedin"
                      target="_blank"
                      title="Linkedin"
                    >
                      <Linkedin />
                    </Link>
                    <Link
                      to={`https://www.instagram.com/?caption=${pageUrl}`}
                      className="social-icon mx-1 social-gplus fa-brands fa-google-plus-g"
                      target="_blank"
                      title="Instagram"
                    >
                      <Instagram />
                    </Link>
                    <Link
                      to={`mailto:?subject=${subject}&body=${pageUrl}`}
                      className="social-icon mx-1 social-mail"
                      target="_blank"
                      title="Mail"
                    >
                      <EnvelopeFill />
                    </Link>
                  </div>
                </div> */}
                {/* Share to social media and wishlist END */}
              </div>
              {/* Content END */}
            </div>
          </div>
          {/* Hero END */}

          {/* Info tabs START */}
          <div className="product-single-tabs">
            {/* Tabs START */}
            <ul className="nav nav-tabs" role="tablist">
              {data?.description && (
                <li className="nav-item">
                  <span
                    className={`cursor-pointer nav-link ${
                      activeTab === "product-desc-content" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("product-desc-content")}
                    role="tab"
                    aria-controls="product-desc-content"
                    aria-selected={activeTab === "product-desc-content"}
                  >
                    {t("description")}
                  </span>
                </li>
              )}

              <li className="nav-item">
                <span
                  className={`cursor-pointer nav-link ${
                    activeTab === "product-reviews-content" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("product-reviews-content")}
                  role="tab"
                  aria-controls="product-reviews-content"
                  aria-selected={activeTab === "product-reviews-content"}
                >
                  {t("reviews")} ({data?.ratingsQuantity})
                </span>
              </li>
              <li className="nav-item ">
                <span
                  className={`cursor-pointer nav-link ${
                    activeTab === "product-spec-content" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("product-spec-content")}
                  role="tab"
                  aria-controls="product-spec-content"
                  aria-selected={activeTab === "product-spec-content"}
                >
                  {t("specifications")}
                  {/* ({data?.customAttributes}) */}
                </span>
              </li>
            </ul>

            {/* Tabs content START */}
            <div className="tab-content">
              {data?.description && (
                <div
                  className={`tab-pane fade ${
                    activeTab === "product-desc-content" ? "show active" : ""
                  }`}
                  id="product-desc-content"
                  role="tabpanel"
                  aria-labelledby="product-tab-desc"
                >
                  <div className={`product-desc-content`}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizedLongDescription,
                      }}
                    />
                  </div>
                </div>
              )}

              {data?.customAttributes && (
                <div
                  className={`tab-pane fade ${
                    activeTab === "product-spec-content" ? "show active" : ""
                  }`}
                  id="product-spec-content"
                  role="tabpanel"
                  aria-labelledby="product-spec-tags"
                >
                  <ul className="Spec_list">
                    {data?.customAttributes?.map((attr) => {
                      return (
                        <li className="col-5">
                          <h5>{attr?.key}</h5>
                          <span>{attr?.value}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div
                className={`tab-pane fade ${
                  activeTab === "product-reviews-content" ? "show active" : ""
                }`}
                id="product-reviews-content"
                role="tabpanel"
                aria-labelledby="product-tab-reviews"
              >
                <div className="product-reviews-content">
                  <div className="reviews-header justify-content-center">
                    {screen === "large" ? (
                      <>
                        <StarRatings
                          rating={data?.ratingsAverage}
                          starRatedColor="#fe9900"
                          starEmptyColor="lightgray"
                          numberOfStars={5}
                          starDimension="25px"
                          starSpacing=".5px"
                        />
                        <h2>{data?.ratingsAverage.toFixed(2)}</h2>
                      </>
                    ) : (
                      <div className="d-block">
                        <StarRatings
                          rating={data?.ratingsAverage}
                          starRatedColor="#fe9900"
                          starEmptyColor="lightgray"
                          numberOfStars={5}
                          starDimension="25px"
                          starSpacing=".5px"
                        />
                        <h2>{data?.ratingsAverage.toFixed(2)}</h2>
                      </div>
                    )}

                    <span
                      onClick={
                        data?.review?.length > 5
                          ? () => navigate(`/product-details/${id}/reviews`)
                          : null
                      }
                    >
                      {data?.ratingsQuantity} &nbsp;
                      {t("review")}
                    </span>
                  </div>
                  <swiper-container slides-per-view="3">
                    {data?.review &&
                      data?.review?.slice(0, 5).map((review, i) => {
                        return (
                          <swiper-slide key={i}>
                            <div className="review_card">
                              <div className="comment-block">
                                <div className="comment-header">
                                  <div
                                    className="ratings-container"
                                    style={{ display: "block" }}
                                  >
                                    <StarRatings
                                      rating={review?.rating}
                                      starRatedColor="#fe9900"
                                      starEmptyColor="lightgray"
                                      numberOfStars={5}
                                      starDimension="15px"
                                      starSpacing=".5px"
                                    />
                                  </div>

                                  <div className="comment-by">
                                    <span>
                                      {" "}
                                      {`${review?.customar?.name?.substring(
                                        0,
                                        3
                                      )}****` || t("loading")}
                                    </span>

                                    <span>
                                      {review?.updatedAt?.split("T")[0]}
                                    </span>
                                  </div>
                                </div>

                                <div className="comment-content">
                                  <p>{review?.review}</p>
                                </div>
                              </div>
                            </div>
                          </swiper-slide>
                        );
                      })}
                  </swiper-container>
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Related products START (SLIDER) */}
          <div className="products-section pt-0 pb-5">
            {alternateProducts.length > 0 && (
              <>
                <h2 className="section-title  m-b-4">{t("relatedProducts")}</h2>

                <ProductSlider
                  slidesPerView={slides}
                  Array={alternateProducts}
                />
              </>
            )}

            <div className="products-section pt-0">
              <h2 className="section-title  m-b-4">
                {t("bestSellingProducts")}
              </h2>
              <ProductSlider slidesPerView={slides} Array={top10Sold} />
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
};

export default ProductDetails;
