import React from "react";
import Loader from "./../Loaders/Loader";
import "./ShoppingCart.css";
import CartHook from "../../hooks/cart/CartHook";
import TransHook from "./../../hooks/translation/trans-hook";
import CartItem from "./CartItem";
import MobileCartItem from "./MobileCartItem";

const ShoppingCart = ({ proceedToCheckout }) => {
  const {
    isCartLoading,
    cart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  } = CartHook(proceedToCheckout);

  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  if (isCartLoading) {
    return <Loader />;
  } else {
    return (
      <div
        className={`row shopping_cart ${lang === "ar" ? "arabic-text" : ""}`}
      >
        {/* Cart items START */}
        <div className="col-lg-8">
          {cart?.data?.cartItems?.map((item, i) => {
            return screen === "large" ? (
              <CartItem
                key={i}
                item={item}
                handleAddToCart={handleAddToCart}
                handleDecreaseCart={handleDecreaseCart}
                handleRemoveItem={handleRemoveItem}
              />
            ) : (
              <MobileCartItem
                key={i}
                item={item}
                handleAddToCart={handleAddToCart}
                handleDecreaseCart={handleDecreaseCart}
                handleRemoveItem={handleRemoveItem}
              />
            );
          })}
        </div>

        {/* Cart totals and shipping START */}
        <div className="col-lg-4">
          <div className="cart-summary">
            <h3>{t("cartTotal")}</h3>

            <table className="table table-totals">
              <tbody>
                <tr>
                  <td>{t("total")}</td>
                  <td>{cartTotal?.toFixed(3)}</td>
                </tr>
              </tbody>
            </table>

            <div className="checkout-methods">
              <button
                disabled={cartTotal <= 0}
                type="submit"
                className="btn btn-block btn-dark"
                onClick={submitCart}
              >
                {t("proceedToCheckout")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ShoppingCart;
