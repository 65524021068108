import React, { useEffect, useState } from "react";
import ShoppingCart from "../../components/Cart/ShoppingCart";
import Checkout from "../../components/Cart/Checkout";
import Navbar from "./../../components/utility/navbar/navbar";
import Footer from "./../../components/utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransHook from "../../hooks/translation/trans-hook";

const CartPage = () => {
  const { t } = TransHook();
  const [activeTab, setActiveTab] = useState("shoppingCart");
  const [cart, setCart] = useState();
  const [cartId, setCartId] = useState();
  const screen = localStorage.getItem("screen") || "large";

  const proceedToCheckout = (cartId) => {
    setCart(cartId);
    setActiveTab("checkout");
  };

  useEffect(() => {
    setCartId(cart);
  }, [cart]);

  const goBackToCart = () => {
    setActiveTab("shoppingCart");
    localStorage.removeItem("cart");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: screen === "large" ? "240px" : "120px" }}
      >
        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
          {activeTab === "shoppingCart" && (
            <li>
              <span>{t("shoppingCart")}</span>
            </li>
          )}
          {activeTab === "checkout" && (
            <li>
              <span>{t("checkout")}</span>
            </li>
          )}
        </ul>

        {activeTab === "shoppingCart" && (
          <ShoppingCart proceedToCheckout={proceedToCheckout} />
        )}
        {activeTab === "checkout" && (
          <Checkout goBackToCart={goBackToCart} cartId={cartId} />
        )}
      </div>
      <ToastContainer autoClose={2000} />
      <Footer />
    </>
  );
};

export default CartPage;
