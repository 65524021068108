import React from "react";
import { Dash, Plus, Trash2Fill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ProductBaseUrl } from "../../api/GlobalData";
import TransHook from "../../hooks/translation/trans-hook";
import { toast } from "react-toastify";

const CartItem = ({
  item,
  handleAddToCart,
  handleDecreaseCart,
  handleRemoveItem,
}) => {
  const { t } = TransHook();

  return (
    <div className="d-flex cart-item-container">
      <div style={{ width: "20%", margin: "10px" }}>
        <Link to={`/product-details/${item?.product?._id}`}>
          <img
            src={ProductBaseUrl + item?.product?.imagesArray[0]?.image}
            alt={item?.name?.split(" ")[0]}
          />
        </Link>
      </div>

      <div style={{ width: "40%" }}>
        <h3>
          <Link
            to={`/product-details/${item?.product?._id}`}
            className="fw-bold text-dark"
            title={item?.name}
          >
            {item?.name?.length > 30
              ? item?.name.slice(0, 30) + "..."
              : item?.name}
          </Link>
        </h3>
        <p>
          {t("productCode")}: {item?.qr}
        </p>
      </div>
      <div
        className="price"
        style={{
          width: "20%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Plus
          className="cursor-pointer"
          size={25}
          onClick={() =>
            item?.maxQuantity > item?.quantity
              ? handleAddToCart(item)
              : toast.error(t("cantAddMore"))
          }
          color="#fe9900"
        />
        <p className="d-flex cart-item-quantity">{item?.quantity}</p>
        <Dash
          className={`cursor-pointer ${
            item?.quantity > 1 ? "" : "cursor-not-allowed"
          } `}
          size={25}
          color="#fe9900"
          onClick={
            item?.quantity > 1
              ? () => handleDecreaseCart(item)
              : () => toast.error(t("cantDecrese"))
          }
        />
      </div>
      <div style={{ width: "20%" }}>
        <div className="Trash" onClick={() => handleRemoveItem(item?._id)}>
          <Trash2Fill size={20} />
        </div>
        <h3 className="text-center mt-2">
          <span style={{ color: "#fe9900" }}>
            ${(item?.taxPrice * item?.quantity).toFixed(3)}
          </span>
          <h5>
            {(item?.taxPrice).toFixed(3)} × {item?.quantity}
          </h5>
        </h3>
      </div>
    </div>
  );
};

export default CartItem;
